import React, { useMemo } from "react";
import { usePage } from "@inertiajs/react";
import MainSiteLayout from "../Layouts/MainSiteLayout";
const Errors = ({ errors, status, message }) => {
    console.log({ errors, status, message });
    const userErrorMessage = useMemo(() => {
        let loaded = true
        if (errors !== null && loaded) {
            switch (typeof errors) {
                case 'string':
                    return <p>{errors}</p>
                    break
                case 'object':
                    return Object.values(errors).map((key,inx) =><p key={`${inx}-${key}`}>{key}</p>
                    )
                    break
                default:
                    return <></>
            }
        }
        return () => {
            loaded = false
        }
    }, [errors])
    const userStatusMessage = useMemo(() => {
        let loaded = true
        if (status !== null && loaded) {
            switch (typeof status) {
                case 'string':
                    return <p>{status}</p>
                    break
                case 'object':
                    return Object.values(status).map((key,inx) =><p key={`${inx}-${key}`}>{key}</p>
                    )
                    break
                default:
                    return <></>
            }
        }
        return () => {
            loaded = false
        }
    }, [status])
    const userMessage = useMemo(() => {
        let loaded = true
        if (message !== null && loaded) {
            switch (typeof message) {
                case 'string':
                    return <p>{message}</p>
                    break
                case 'object':
                    return Object.values(message).map((key,inx) =><p key={`${inx}-${key}`}>{key}</p>
                    )
                    break
                default:
                    return <></>
            }
        }
        return () => {
            loaded = false
        }
    }, [ message])
    return (
        <MainSiteLayout>
            <div className="inner-wrapper">
                {userErrorMessage}
                {userStatusMessage}
                {userMessage}
            </div>
        </MainSiteLayout>
    );
};

export default Errors;
