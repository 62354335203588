import ViewCallback from "./hooks/ViewCallback";
import LabeledInput from "../../Components/LabeledInput";
import { useForm } from "@inertiajs/react";
export default function Generated() {
    const { data, setData } = useForm({ selected: "text" });
    const formatOptions = ["text", "json", "html"];
    return (
        <div className="flex gap-2 flex-col">
            <p>select format</p>
            <section className="flex gap-2">
                {formatOptions.map((val, key) => (
                    <LabeledInput
                        type="radio"
                        key={key}
                        name="select_format"
                        handleChange={(event) => {
                            setData({ selected: val });
                            console.log({ val, data }, event.target.value);
                        }}
                        value={val}
                        checked={data.selected === val}
                    >
                        {val}
                    </LabeledInput>
                ))}
            </section>
            <div className="flex gap-4 flex-col lg:flex-row">
                {ViewCallback(data.selected)}
            </div>
        </div>
    );
}
