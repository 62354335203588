import TextOutput from "../com/TextOutput";
import JsonOutput from "../com/JsonOutput";
import { useMemo } from "react";
import ConversionHook from "./ConversionHook";
export default function ViewCallback(formatState) {
    const paragraphs = ConversionHook();
    const FormatView = useMemo(() => {
        switch (formatState) {
            case "json":
                return <JsonOutput paragraphs={paragraphs} />;
            default:
                return <TextOutput paragraphs={paragraphs} />;
        }
    }, [formatState, paragraphs]);
    return FormatView;
}
