import MainSiteLayout from "./MainSiteLayout";

export default function Authenticated({ children }) {
    return (
        <MainSiteLayout>
            <div className="module">
                <article className="inner-wrapper">
                    <section className="appointee-container">
                        {children}
                    </section>
                </article>
            </div>
        </MainSiteLayout>
    );
}
