import MainSiteLayout from "./MainSiteLayout";
import ApplicationLogo from "../Components/ApplicationLogo";
export default function Guest({ children }) {
    return (
        <MainSiteLayout>
            <ApplicationLogo />
            <section className="inner-wrapper">{children}</section>
        </MainSiteLayout>
    );
}
