import { FaClipboard } from "react-icons/fa";
import PrimaryButton from "../../../Components/PrimaryButton";
import { toast } from "react-toastify";
import useClipboardApi from "use-clipboard-api";
export default function TextOutput({ paragraphs }) {
    const [value, copy] = useClipboardApi();
    return (
        paragraphs && (
            <ul className="px-4 lg:p-0 lg:max-w-lg max-h-[600px] overflow-y-auto">
                <PrimaryButton
                    onClick={() => {
                        copy(paragraphs);
                        toast.info("text cpoied to clipboard");
                    }}
                >
                    <FaClipboard />
                </PrimaryButton>
                {Array.isArray(paragraphs) &&
                    paragraphs.map((line, index) => (
                        <li className="mb-4" key={index}>
                            {line}
                        </li>
                    ))}
            </ul>
        )
    );
}
