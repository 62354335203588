import React from "react";
import { Link } from "@inertiajs/react";
import UL from "./UL";
import { usePage } from "@inertiajs/react";
import LI from "./LI";
import { objectToArray } from "../Utils/utils";

const Footer = () => {
    const {
        menus: { footer: menu },
        auth: { user },
    } = usePage().props;
    const data = React.useMemo(() => objectToArray(menu), [menu]);
    return (
        <footer className={`module footer`}>
            <section className="footer-list">
            </section>
        </footer>
    );
};

export default Footer;
