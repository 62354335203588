import './bootstrap';

import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { createInertiaApp } from '@inertiajs/react';
import { InertiaProgress } from '@inertiajs/progress';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Wish I was there with you';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}`),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<StrictMode><App {...props} /></StrictMode>);
    },
});

InertiaProgress.init({ color: '#4B5563' });
