import { useForm, usePage } from "@inertiajs/react";
import PrimaryButton from "../../Components/PrimaryButton";
import LabeledInput from "../../Components/LabeledInput";
export default function GeneratorForm() {
    const { generatorData } = usePage().props;
    const { data, setData, post, processing, errors, reset } = useForm({
        amount: generatorData?.amount || 5,
        selected_format: generatorData?.selected_format || "paragraphs",
    });
    const onHandleChange = (event) => {
        setData(
            event.target.name,
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value
        );
    };
    const submit = (e) => {
        e.preventDefault();

        post(route("gene"));
    };
    const radiogroup = ["paragraphs", "sentences", "words", "names"];
    return (
        <form className="form flex flex-col gap-4 mb-4" onSubmit={submit}>
            <div className="flex items-center gap-4">
                <section className="inline-flex flex-col">
                    <LabeledInput
                        className="text-black "
                        name="amount"
                        type="number"
                        value={data.amount}
                        handleChange={onHandleChange}
                        max={100}
                        errors={errors.amount}
                    />
                </section>
                <section className="inline-flex flex-col">
                    {radiogroup.map((val, key) => (
                        <LabeledInput
                            key={key}
                            type="radio"
                            name="selected_format"
                            handleChange={onHandleChange}
                            value={val}
                            checked={data.selected_format === val}
                            labelClass="radio_btn"
                        >
                            <span>{val}</span>
                        </LabeledInput>
                    ))}
                </section>
            </div>
            <PrimaryButton>submit</PrimaryButton>
        </form>
    );
}
