import React from "react";
import { usePage } from "@inertiajs/react";
import Navigation from "../Components/Navigation";
import ErrorWithToast from "../Hooks/error_with_toast";
import Footer from "../Components/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function MainSiteLayout({ children }) {
    const { url } = usePage();
    const pageClass = React.useMemo(
        () => (!!url && url === "/" ? "_home" : url.replace("/", "_")),
        [url]
    );
    return (
        <>
            {/* <Navigation /> */}
            <main className="flex items-center justify-center">
                <section className="body-wrapper">{children}</section>
            </main>
            <Footer />
            <ErrorWithToast />
            <ToastContainer />
        </>
    );
}
