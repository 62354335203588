import { useEffect } from 'react'
import { usePage } from '@inertiajs/react'
const ErrorWithToast = () => {
    const { alerts, errors, warnings } = usePage().props
    const props = usePage().props
    useEffect(() => {
        let loaded = true
        if (errors !== null && loaded) {
            switch (typeof errors) {
                case 'string':
                    console.log({errors})
                    break
                case 'object':
                    Object.keys(errors).forEach(key =>
                        console.log(errors[key])
                    )
                    break
                default:
                    return
            }
        }
        return () => {
            loaded = false
        }
    }, [errors])
    useEffect(() => {
        let loaded = true
        if (alerts !== null && loaded) {
            switch (typeof alerts) {
                case 'string':
                    console.log({alerts})
                    break
                case 'object':
                    Object.keys(alerts).forEach(key =>
                        console.log({alerts})
                    )
                    break
                default:
                    return
            }
        }
        return () => {
            loaded = false
        }
    }, [alerts])
    useEffect(() => {
        let loaded = true
        if (warnings !== null && loaded) {
            switch (typeof warnings) {
                case 'string':
                    console.log({warnings})
                    break
                case 'object':
                    Object.keys(warnings).forEach(key =>
                        console.log({warnings})
                    )
                    break
                default:
                    return
            }
        }
        return () => {
            loaded = false
        }
    }, [warnings])
    return null
}

export default ErrorWithToast
