import InputLabel from "./InputLabel";
import InputError from "./InputError";
import TextInput from "./TextInput";
export default function LabeledInput({
    errors,
    labelClass,
    children,
    ...props
}) {
    return (
        <InputLabel className={labelClass || "inline-flex gap-2"}>
            <TextInput {...props} />
            {children}
            <InputError message={errors} className="mt-2" />
        </InputLabel>
    );
}
