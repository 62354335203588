import { useForm, usePage } from "@inertiajs/react";
import GeneratorForm from "./GeneratorForm";
export default function Generator() {
    return (
        <div className="relative z-10 backdrop-blur-xl p-6 backdrop-contrast-100 shadow-lg bg-black bg-opacity-40 text-white ">
            <p className="flex items-center p-2 mb-4 rounded-full bg-black bg-opacity-40 justify-center">
                Generate your dummy data
            </p>
            <GeneratorForm />
        </div>
    );
}
