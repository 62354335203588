import { Head } from "@inertiajs/react";
import MainSiteLayout from "../Layouts/MainSiteLayout";
import PageHeader from "../Components/PageHeader";
import Generator from "../Modules/Generator";
import Generated from "../Modules/Generated";
export default function Welcome(props) {
    return (
        <MainSiteLayout>
            <Head title="Welcome" />

            <PageHeader
                page="Welcome"
                imageSource={"https://picsum.photos/966/600/"}
            >
                <p className="text-5xl mb-2 uppercase relative invert backdrop-blur-sm bg-blend-difference mix-blend-difference p-2 rounded-full">
                    Dummy Dump
                </p>
                <Generator />
            </PageHeader>
            <Generated />
        </MainSiteLayout>
    );
}
