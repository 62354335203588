import { FaClipboard } from "react-icons/fa";
import PrimaryButton from "../../../Components/PrimaryButton";
import { toast } from "react-toastify";
import useClipboardApi from "use-clipboard-api";
export default function JsonOutput({ paragraphs }) {
    const [value, copy] = useClipboardApi();
    return (
        paragraphs && (
            <pre className="px-4 lg:p-0 lg:max-w-lg max-h-[600px] overflow-y-auto block">
                <PrimaryButton
                    onClick={() => {
                        copy(JSON.stringify(paragraphs));
                        toast.info("json cpoied to clipboard");
                    }}
                >
                    <FaClipboard />
                </PrimaryButton>
                <code className="block"><p>{JSON.stringify({data:paragraphs})}</p></code>
            </pre>
        )
    );
}
