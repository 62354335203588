import { useEffect } from 'react'
import { Head, Link, useForm } from '@inertiajs/react'
import GuestLayout from '../../Layouts/GuestLayout'
import InputError from '../../Components/InputError'
import InputLabel from '../../Components/InputLabel'
import PrimaryButton from '../../Components/PrimaryButton'
import TextInput from '../../Components/TextInput'
export default function Register () {
    const { data, setData, post, processing, errors, reset } = useForm({
        // first_name: '',
        // last_name: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    })

    useEffect(() => {
        return () => {
            reset('password', 'password_confirmation')
        }
    }, [])

    const onHandleChange = event => {
        setData(
            event.target.name,
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
        )
    }

    const submit = e => {
        e.preventDefault()

        post(route('register'))
    }

    return (
            <GuestLayout>
            <Head title='Register' />
                <form onSubmit={submit}>
                    {/* <div className='mt-4'>
                        <InputLabel forInput='first_name' value='First Name' />

                        <TextInput
                            id='first_name'
                            type='text'
                            name='first_name'
                            value={data.first_name}
                            className='mt-1 block w-full'
                            autoComplete='username'
                            isFocused={true}
                            required
                            handleChange={onHandleChange}
                        />

                        <InputError message={errors.email} className='mt-2' />
                    </div>
                    <div className='mt-4'>
                        <InputLabel forInput='last_name' value='Last Name' />

                        <TextInput
                            id='last_name'
                            type='text'
                            name='last_name'
                            value={data.last_name}
                            className='mt-1 block w-full'
                            autoComplete='username'
                            isFocused={true}
                            handleChange={onHandleChange}
                        />

                        <InputError message={errors.email} className='mt-2' />
                    </div> */}
                    <div className='mt-4'>
                        <InputLabel forInput='name' value='Name' />

                        <TextInput
                            id='name'
                            type='text'
                            name='name'
                            value={data.name}
                            className='mt-1 block w-full'
                            autoComplete='username'
                            handleChange={onHandleChange}
                        />

                        <InputError
                            message={errors.name}
                            className='mt-2'
                        />
                    </div>

                    <div className='mt-4'>
                        <InputLabel forInput='email' value='Email' />

                        <TextInput
                            id='email'
                            type='email'
                            name='email'
                            value={data.email}
                            className='mt-1 block w-full'
                            autoComplete='email'
                            isFocused={true}
                            handleChange={onHandleChange}
                        />

                        <InputError message={errors.email} className='mt-2' />
                    </div>

                    <div className='mt-4'>
                        <InputLabel forInput='password' value='Password' />

                        <TextInput
                            id='password'
                            type='password'
                            name='password'
                            value={data.password}
                            className='mt-1 block w-full'
                            autoComplete='new-password'
                            handleChange={onHandleChange}
                            required
                        />

                        <InputError
                            message={errors.password}
                            className='mt-2'
                        />
                    </div>

                    <div className='mt-4'>
                        <InputLabel
                            forInput='password_confirmation'
                            value='Confirm Password'
                        />

                        <TextInput
                            id='password_confirmation'
                            type='password'
                            name='password_confirmation'
                            value={data.password_confirmation}
                            className='mt-1 block w-full'
                            handleChange={onHandleChange}
                            required
                        />

                        <InputError
                            message={errors.password_confirmation}
                            className='mt-2'
                        />
                    </div>

                    <div className='flex items-center justify-end mt-4'>
                        <Link
                            href={route('login')}
                            className='underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                            Already registered?
                        </Link>

                        <PrimaryButton className='ml-4' processing={processing}>
                            Register
                        </PrimaryButton>
                    </div>
                </form>
            </GuestLayout>
    )
}
