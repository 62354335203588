import { usePage } from "@inertiajs/react";
import { useMemo } from "react";
import { faker } from "@faker-js/faker";
import { createArrayFromNumber } from "../../../Utils/utils";
export default function ConversionHook() {
    const { generatorData } = usePage().props;
    const paragraphs = useMemo(() => {
        if (generatorData?.amount && generatorData?.selected_format) {
            switch (generatorData?.selected_format) {
                case "sentences":
                    return faker.lorem
                        .sentences(generatorData?.amount, ",")
                        .split(",");
                case "words":
                    return faker.lorem.words(generatorData?.amount).split(" ");
                case "names":
                    return createArrayFromNumber(generatorData?.amount).map(
                        () => faker.name.fullName()
                    );
                default:
                    return faker.lorem
                        .paragraphs(generatorData?.amount, ",")
                        .split(",");
            }
        }
        return "";
    }, [generatorData, generatorData?.amount, generatorData?.selected_format]);

    return paragraphs ? paragraphs : null;
}
