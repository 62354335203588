export const objectToArray = (objectOrArray) => {
    if (objectOrArray) {
        switch (typeof objectOrArray) {
            case "object":
                return [...Object.values(objectOrArray)];
            case "array":
                return objectOrArray;
            default:
                return [];
                break;
        }
    }
    return [];
};

export const objectToFormData = (obj) => {
    const formDataValue = new FormData();
    Object.keys(obj).map((x) => formDataValue.append(x, obj[x]));
    return formDataValue;
};

export const CeditCardMask = [
    {
        mask: "0000 000000 00000",
        regex: "^3[47]\\d{0,13}",
        cardtype: "american express",
    },
    {
        mask: "0000 0000 0000 0000",
        regex: "^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}",
        cardtype: "discover",
    },
    {
        mask: "0000 000000 0000",
        regex: "^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}",
        cardtype: "diners",
    },
    {
        mask: "0000 0000 0000 0000",
        regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
        cardtype: "mastercard",
    },
    // {
    //     mask: '0000-0000-0000-0000',
    //     regex: '^(5019|4175|4571)\\d{0,12}',
    //     cardtype: 'dankort'
    // },
    // {
    //     mask: '0000-0000-0000-0000',
    //     regex: '^63[7-9]\\d{0,13}',
    //     cardtype: 'instapayment'
    // },
    {
        mask: "0000 000000 00000",
        regex: "^(?:2131|1800)\\d{0,11}",
        cardtype: "jcb15",
    },
    {
        mask: "0000 0000 0000 0000",
        regex: "^(?:35\\d{0,2})\\d{0,12}",
        cardtype: "jcb",
    },
    {
        mask: "0000 0000 0000 0000",
        regex: "^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}",
        cardtype: "maestro",
    },
    // {
    //     mask: '0000-0000-0000-0000',
    //     regex: '^220[0-4]\\d{0,12}',
    //     cardtype: 'mir'
    // },
    {
        mask: "0000 0000 0000 0000",
        regex: "^4\\d{0,15}",
        cardtype: "visa",
    },
    {
        mask: "0000 0000 0000 0000",
        regex: "^62\\d{0,14}",
        cardtype: "unionpay",
    },
    {
        mask: "0000 0000 0000 0000",
        cardtype: "Unknown",
    },
];

export const FORM_DATA = (file) => {
    console.log({ file });
    let formData = new FormData();
    formData.append("myFile", file, file.name);

    return formData;
};

export const createArrayFromNumber = (number) => {
    return Array.apply(null, { length: number }).map(Function.call, Number);
};

export function createRandomWord(length) {
    var consonants = "bcdfghjklmnpqrstvwxyz",
        vowels = "aeiou",
        rand = function (limit) {
            return Math.floor(Math.random() * limit);
        },
        i,
        word = "",
        // length = parseInt(length, 5),
        consonants = consonants.split(""),
        vowels = vowels.split("");
    for (i = 0; i < length / 2; i++) {
        var randConsonant = consonants[rand(consonants.length)],
            randVowel = vowels[rand(vowels.length)];
        word += i === 0 ? randConsonant.toUpperCase() : randConsonant;
        word += i * 2 < length - 1 ? randVowel : "";
    }
    return word;
}
export function createRandomSentence(
    sentenceLength,
    minumWordLength,
    maxWordLength
) {
    const minValue =
        minumWordLength ||
        Math.round(Math.max(3, Math.round(Math.random() * 12)));
    const maxValue =
        maxWordLength ||
        Math.round(Math.max(13, Math.round(Math.random() * 18)));
    const makeSentence = createArrayFromNumber(sentenceLength).map(() =>
        createRandomWord(Math.random() * 1 > 0.5 ? minValue : maxValue)
    );
    const returnSentence = String([...makeSentence].join(" ")).toLowerCase();
    return `${
        returnSentence.charAt(0).toUpperCase() + returnSentence.slice(1)
    }.`;
}
export function createRandomParagraph(
    paragraphLength,
    minumAmountOfSentences,
    maxAmountOfSentences
) {
    const minValue =
        minumAmountOfSentences ||
        Math.round(Math.max(3, Math.round(Math.random() * 8)));
    const maxValue =
        maxAmountOfSentences ||
        Math.round(Math.max(9, Math.round(Math.random() * 15)));
    return createArrayFromNumber(paragraphLength).map(() =>
        createArrayFromNumber(Math.max(3, Math.round(Math.random() * 8)))
            .map(() =>
                createRandomSentence(
                    Math.random() * 1 > 0.5 ? minValue : maxValue
                )
            )
            .join(" ")
    );
}
