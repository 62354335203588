import React, { useMemo, useRef } from "react";
export default function PageHeader({imageSource,page,children,...props}) {
const imgSrc = useMemo(()=>imageSource,[imageSource])
    return (
        <header className="flex flex-col items-start m-4 rounded-lg overflow-hidden w-screen h-screen shadow-lg max-w-7xl max-h-[600px] items-center justify-center relative p-4">
            <img className='w-full h-full object-center object-cover absolute' src={imgSrc} title={` ${page || ''} page haeder`} alt='header image for page' />
            {children}
        </header>
    );
};
